<template>
  <div ref="Product ">
    <v-container fluid class="Productos">
      <v-container>
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <h2 class="mb-5">Productos</h2>
            <p class="mb-5">
              We deliver well suited solutions that ensure business
              sustainability and high performance.
            </p>
            <ul class="mb-6 center">
              <li v-for="i in data_productos" :key="i.id">
                <v-btn
                  class="ml-6"
                  :color="i.color"
                  :outlined="i.id == active.id ? false : true"
                  small
                  elevation="1"
                  rounded
                  @click="Cambio(i)"
                  >{{ i.name }}</v-btn
                >
              </li>
            </ul>
          </v-col>
        </v-row>
        <v-row class="ocho-sections">
          <!-- <h1 @click="toScroll('Product', 0)" class="color-primary produ">
            Productos
          </h1> -->
          <transition name="fade">
            <v-col
              v-if="show"
              class="card-ocho mb-6"
              cols="12"
              sm="12"
              xs="12"
              md="5"
            >
              <img :src="active.logo" class="logo-ocho" alt="" srcset="" />
              <p class="mt-4">{{ active.text }}</p>
              <v-btn :color="active.color" elevation="12" raised rounded
                ><a :href="active.link"></a> See more</v-btn
              >
            </v-col>
          </transition>
          <transition>
            <v-col v-if="show" cols="12" sm="12" md="7" xs="12">
              <img
                :src="active.mockup"
                class="Mockup_ocho"
                width="20px"
                srcset=""
              />
            </v-col>
          </transition>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Productos",

  data: () => ({
    messages: null,
    show: true,
    active: {
      id: 0,
      name: "Ocho app",
      logo: require("@/assets/logo-ocho.svg"),
      mockup: require("@/assets/portafolio/mockup/Mockup_ocho.png"),
      text: "Que las pequeñas tareas del día a día no roben tu tiempo, disfruta tu vida de la manera mas fácil.\n\nOcho es una aplicación multicategoría para compras y envíos a demanda, donde podrás encontrar restaurantes, supermercados, entradas a eventos, servicios de courier (mensajería), entrega de efectivo, etc.",
      color: "#ff4700",
      web: "https://ocho.life/",
    },
    data_productos: [
      {
        id: 0,
        name: "Ocho app",
        logo: require("@/assets/logo-ocho.svg"),
        mockup: require("@/assets/portafolio/mockup/Mockup_ocho.png"),
        text: "Que las pequeñas tareas del día a día no roben tu tiempo, disfruta tu vida de la manera mas fácil.\n\nOcho es una aplicación multicategoría para compras y envíos a demanda, donde podrás encontrar restaurantes, supermercados, entradas a eventos, servicios de courier (mensajería), entrega de efectivo, etc.",
        color: "#ff4700",
        web: "https://ocho.life/",
      },
      {
        id: 1,
        name: "Wink",
        logo: require("@/assets/logo-wink.svg"),
        mockup: require("@/assets/wink-pantalla-left.jpg"),
        text: "Te brinda completo control y autonomía sobre tu digital signage de manera fácil y 100% remota.\n\nEn el mercado cada día es más frecuente encontrar presencia de pantallas digitales en ambientes de retail, restaurantes, comida rápida, hospitales, hoteles y hasta en baños. \n \n Sin embargo estas pantallas normalmente son administradas de manera empírica y manual. Continúa leyendo para que descubras cómo creamos un sistema especializado para el manejo de diversas pantallas en diferentes localidades de manera remota.",
        color: "#00d5ee",
        web: "https://ocho.life/",
      },
    ],
  }),
  methods: {
    Cambio(n) {
      this.show = false;

      this.active = n;
      console.log(this.active);
      setTimeout(() => {
        this.show = true;
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/_responsive.scss";
@import "@/main.scss";

.Productos {
  height: auto;
  // background-color:#161616;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 100px;
  padding-top: 100px;
  padding-left: 10%;
  padding-right: 10%;
  color: #000 !important;
}
.produ {
  z-index: 1000;
}
.logo-ocho {
  width: 200px;
}
.Mockup_ocho {
  width: 80%;
}
.card-ocho {
  text-align: left;
}
.ocho-sections {
  margin-top: 150px;
  height: 400px;
  @include responsive(mobile) {
    height: auto;
    min-height: 250px;
    //... all what you want
  }
}
.text-color-ocho {
  color: #ff4700;
  text-transform: uppercase;
}
.color-ocho {
  background-color: #ff4700 !important;
}
li {
  display: inline;
}
.theme--dark.v-divider {
  border-color: #1616161f;
}
.center {
  display: flex;
  justify-content: center;
  padding-left: 0px !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
