<template>
  <div>
    <div class="home">
      <img src="@/assets/texture/left.svg" class="left-texture" srcset="" />
      <img src="@/assets/texture/rigth.svg" class="rigth-texture" srcset="" />
      <div class="index">
        <img src="@/assets/centro.svg" alt="" srcset="" />
        <div class="titulo-index">
          <v-card-text class="text-h4 font-weight-bold">
            <v-card-subtitle class="text-h6">
              Professional engineering and design suited for your IT needs.
              
              <!-- We help you define your strategic innovation roadmap, build
              capabilities to accelerate your pace, accelerate your development
              process, and maximize market adoption. -->
            </v-card-subtitle>
          </v-card-text>
          <img src="@/assets/scroll.svg" class="scroll" alt="" srcset="" />
        </div>
      </div>
    </div>

    <v-container class="margin-sections">
      <v-row class="list-desc" v-scroll-reveal.reset>
        <v-col v-for="c in data_desc" class="desc rounded-xl" :key="c.item" cols="12" sm="3" md="3">
          <div class="cart-desc gradient-box color-primary rounded-xl">
            {{ c.text }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <section style="position: relative">
      <img src="@/assets/texture/left.svg" class="left-texture" srcset="" />
      <img src="@/assets/texture/rigth.svg" class="rigth-texture" srcset="" />

      <v-container class="abaut-sect margin-sections">
        <v-row v-scroll-reveal.reset>
          <v-col class="col-abaut" cols="12" md="12" sm="12">
            <h1 class="font-size-sd-1 mb-3">
              <b> <b class="color-primary">About </b> Us</b>
            </h1>
            <p class="p-abaut">
              We’re experts with 10+ years experience in helping companies define their innovation roadmap & building software to accelerate their pace. <br><br>
Our main drivers are security, flexibility & delivering intuitive interfaces that are visually pleasing & highly functional.
            </p>
          </v-col>
          <!-- <v-col class="mobile-none" cols="12" md="4" sm="4">
          <img
            src="@/assets/nucleo.svg"
            class="mt-10"
            width="60%"
            alt=""
            srcset=""
          />
        </v-col> -->
        </v-row>
      </v-container>
    </section>
    <div class="core">
      <v-container class="margin-sections">
        <v-row v-scroll-reveal.reset>
          <v-col cols="12" md="6">
            <img src="@/assets/stack.svg" width="100%" alt="" />
          </v-col>
          <v-col cols="12" md="6">
            <h2 class="text-left font-size-sd-1">
              <b><b class="color-primary">Core</b> technologies</b>
            </h2>
            <v-row>
              <v-col cols="12" md="12" class="titulo-stack">
                <!-- <h4 class="color-primary text-left font-size-sd-1">
                  Mobile
                </h4> -->
              </v-col>
            </v-row>
            <v-row v-scroll-reveal.reset>
              <v-col v-for="(i, index) in stack_web" :key="index" cols="6" sm="4" md="4">
                <div class="stack-list">
                  <h3 class="color-primary">{{ i.titulo }}</h3>
                  <ul>
                    <li v-for="(m, index) in i.list" :key="index">
                      <!-- <v-icon small :color="m.color">{{ m.icon }}</v-icon> -->
                      {{ m.text }}
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-scroll-reveal.reset>
          <v-col cols="12" class="titulo-stack">
            <!-- <h4 class="color-primary font-size-sd-1">Web</h4> -->
          </v-col>
        </v-row>
        <!-- <v-spacer></v-spacer>
        <v-row class="">
          <v-col
            v-for="(i, index) in stack_web"
            :key="index"
            cols="6"
            sm="3"
            md="3"
          >
            <div class="stack-list">
              <h3 class="color-primary">{{ i.titulo }}</h3>
              <ul>
                <li v-for="(c, index) in i.list" :key="index">
        
                  {{ c.text }}
                </li>
              </ul>
            </div>
          </v-col>
        </v-row> -->
      </v-container>
    </div>

    <!-- <section class="productoss">
      <Productos />
    </section> -->
    <section class="banner-secctions mx-mobile">
      <div class="container wrap-banner">
        <div class="texto-CTA text-left">
          <h1>
            <b>Let's <b style="color: #fff !important">develop</b> your project
              together!
            </b>
          </h1>
          <v-btn class="mt-6" color="dark" elevation="5" raised rounded to="/contact">
            Contact us</v-btn>
        </div>
        <div class="img-CAT">
          <img src="@/assets/web.svg" width="300px" alt="" srcset="" />
        </div>
      </div>
    </section>
    <section class="marcas-confi margin-sections">
      <v-container>
        <h1 class="font-size-sd-1">
          <b> <b class="color-primary">Brands</b> that trust us</b>
        </h1>
        <v-row>
          <v-col class="my-6" cols="6" md="3" sm="3" v-for="(lo, index) in mark" :key="index">
            <img :src="lo.logo" alt="" width="60%" class="gris" srcset="" />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- <v-container class="margin-sections">
      <section>
        <h1 class="font-size-sd-1">
          <b class="color-primary">Partners</b>
          <b> and <b class="color-primary">Marcas</b> </b>
        </h1>
        <h3 class="mt-2 mb-12">
          Trabajamos con muchas marcas importantes y reconocidas a nivel
          internacional, aumentando así la integridad y estabilidad de nuestros
          proyectos
        </h3>
        <v-row class="mt-6">
          <v-col
            cols="12"
            md="6"
            sm="6"
            v-for="(p, index) in Partners"
            :key="index"
          >
            <img :src="p.logo" width="150px" class="mb-3" />
            <h3 class="ma-4">{{ p.name }}</h3>
            <p class="ma-4">{{ p.descrip }}</p>
          </v-col>
        </v-row>
      </section>
    </v-container> -->
  </div>
</template>

<script>
// @ is an alias to /src

import Team from "@/components/Team.vue";
import BeanTeam from "@/components/BeanTeam.vue";
import Productos from "@/components/Productos.vue";
import Wedo from "@/components/wedo.vue";

export default {
  name: "Home",
  components: {
    Team,
    BeanTeam,
    Productos,
    Wedo,
  },
  data() {
    return {
      mark: [
        { logo: require("@/assets/clientes/logo-01.png") },
        { logo: require("@/assets/clientes/logo-04.png") },
        { logo: require("@/assets/clientes/logo-05.png") },
        { logo: require("@/assets/clientes/logo-03.png") },
        { logo: require("@/assets/clientes/logo-02.png") },
        { logo: require("@/assets/clientes/logo-07.png") },
        { logo: require("@/assets/clientes/logo-08.png") },
        { logo: require("@/assets/clientes/logo-06.png") },
        { logo: require("@/assets/clientes/logo-09.png") },
        { logo: require("@/assets/clientes/logo-10.png") },
        { logo: require("@/assets/clientes/logo-11.png") },
        { logo: require("@/assets/clientes/labmed.svg") },
        // { logo: require("@/assets/clientes/logo-12.png") },
        // { logo: require("@/assets/clientes/peach-logo.png") },
        // { logo: require("@/assets/clientes/logo-12.png") },
        // { logo: require("@/assets/clientes/logo-12.png") },
      ],
      Partners: [
        {
          name: "Chips",
          logo: require("@/assets/marcas_logos/Intel.png"),
          descrip:
            "Intel Corporation es el mayor fabricante de circuitos integrados del mundo. La compañía estadounidense es la creadora de la serie de procesadores más comunes en las computadores personales.",
        },
        {
          name: "Métodos de Pago",
          logo: require("@/assets/marcas_logos/idt-logo.png"),
          descrip:
            "IdTech se ha establecido como uno de los mayores fabricantes de periféricos para métodos de pago con banda magnética, EMV y NFC.",
        },
        {
          name: "Periféricos",
          logo: require("@/assets/marcas_logos/logitech-logo.png"),
          descrip:
            "Logitech es una empresa dedicada a la electrónica que fabrica periféricos para computadoras personales, principalmente: mouse, teclados, cámaras y audífonos.",
        },
        {
          name: "Suite Linux",
          logo: require("@/assets/marcas_logos/balena.png"),
          descrip:
            "Balena es un completo set de herramientas para desarrollo, deployment y administración de flotas conectadas a dispositivos Linux",
        },
      ],
      Servicess: false,
      Specialists: [
        "Automated QA",
        "Manual QA",
        "Business analyst",
        "Project manager",
      ],
      data_desc: [
        { id: 0, text: "CUSTOM SOFTWARE DEVELOPMENT", img: "" },
        { id: 1, text: "IT CONSULTANT AND DIGITAL ADVISORY", img: "" },
        { id: 2, text: "SOFTWARE REENGINEERING AND SUPPORT", img: "" },
        { id: 3, text: "MOBILE SOFTWARE PRODUCT DEVELOPMENT", img: "" },
      ],
      stack_web: [
        {
          titulo: "Frontend",
          list: [
            // { text: "React", icon: "fab fa-react", color: "#61dafb" },
            { text: "Angular", icon: "fab fa-angular", color: "#f10026" },
            { text: "Vue", icon: "fab fa-vuejs", color: "#00b67a" },
            { text: "JavaScript", icon: "fab fa-js-square", color: "#f2d900" },
            { text: "TypeScript", icon: "fab fa-react", color: "#61dafb" },
            { text: "HTML5", icon: "fab fa-react", color: "#61dafb" },
          ],
        },
        {
          titulo: "Backend",
          list: [
            { text: "Ruby", icon: "fab fa-angular", color: "#f10026" },
            { text: "Node.js", icon: "fab fa-node-js", color: "#74c200" },
            { text: "Go", icon: "fab fa-go", color: "#f10026" },
            { text: "Java", icon: "fab fa-angular", color: "#f10026" },
            { text: "Python", icon: "fab fa-python", color: "#f10026" },
            { text: "TypeScript", icon: "fab fa-angular", color: "#f10026" },
          ],
        },
        {
          titulo: "Database",
          list: [
            { text: "PostgreSQL", icon: "fab fa-angular", color: "#fff" },
            { text: "MongoDB", icon: "fab fa-angular", color: "#fff" },
            { text: "MySQL", icon: "fab fa-angular", color: "#fff" },
            { text: "AWS RDS", icon: "fab fa-aws", color: "#f2d900" },
          ],
        },
        {
          titulo: "Cloud",
          list: [
            { text: "AWS", icon: "fab fa-aws", color: "#fff" },
            { text: "Google Cloud", icon: "fab fa-google", color: "#fff" },
            { text: "Azure", icon: "fab fa-aws", color: "#fff" },
          ],
        },
        {
          titulo: "DevOps",
          list: [
            { text: "Docker", icon: "fab fa-docker", color: "#0091ed" },
            { text: "Dokku", icon: "fab fa-aws", color: "#fff" },
            { text: "Kubernetes", icon: "fab fa-aws", color: "#fff" },
          ],
        },
        // ],
        // stack_mobile: [
        {
          titulo: "Android",
          list: [
            { text: "Java", icon: "fab fa-java", color: "#fFF" },
            { text: "Kotlin", icon: "fab fa-kotlin", color: "#f10026" },
          ],
        },
        {
          titulo: "iOS",
          list: [
            { text: "Swift", icon: "fab fa-swift", color: "#ff440a" },
            { text: "Objetive-C", icon: "fab fa-angular", color: "#f10026" },
          ],
        },
        {
          titulo: "Cross-platform",
          list: [
            { text: "Flutter", icon: "fab fa-angular", color: "#f10026" },
            // { text: "Reac-Native", icon: "fab fa-react", color: "#61dafb" },
            { text: "Ionic", icon: "fab fa-angular", color: "#f10026" },
            { text: "Capacitor", icon: "fab fa-angular", color: "#f10026" },
          ],
        },
      ],
    };
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
  methods: {},
};
</script>
<style lang="scss" >
@import "@/_responsive.scss";
@import "@/main.scss";

.core {
  background-image: url("../assets/texture/left.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
}

.left-texture {
  position: absolute;
  width: 50%;
  left: -10%;
  opacity: 0.2;
  top: 10%;
}

.rigth-texture {
  position: absolute;
  width: 60%;
  right: -20%;
  opacity: 0.2;
  top: -50% !important;
}

.home {
  z-index: 2;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: flex-start;
  position: relative;

  .index {
    height: 100vh;
    width: 100%;
    background-image: url("../assets/fondo.svg");
    background-size: cover;
    background-position: center;
    color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    @include responsive(mobile) {
      width: 100%;
      //... all what you want
    }

    img {
      width: 40%;

      @include responsive(mobile) {
        width: 100%;
        //... all what you want
      }
    }

    .titulo-index {
      width: 60%;

      @include responsive(mobile) {
        width: 100%;
        //... all what you want
      }

      text-align: center;

      .scroll {
        width: 20px;
        margin-bottom: 40px;
      }
    }
  }
}

// .nuestro{

//   background-image: url("../assets/fondo-sec_uno.svg");
//     background-size: contain;
//     background-position: top ;

// }'
.contenedor {
  width: 80%;
  height: 200vh;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-top: 0px;
  flex-wrap: wrap;
}

.nucleo {
  width: 250px;
  height: auto;
  opacity: 1;
}

.nucleo-2 {
  width: 300px;
  opacity: 0;
}

.titulo-seccions {
  width: 100%;
  text-align: left;
  color: white;
  height: auto;
  opacity: 1;
}

.titulo-seccions-2 {
  width: 100%;
  text-align: left;
  color: white;
  height: auto;
}

.img-titulo {
  width: 500px;
  color: #fff;
  opacity: 0;

  h1 {
    width: 100%;
    margin-bottom: 20px;
  }
}

.Metodos {
  // background-color: #fff;
  display: flex;
}

.list-desc {
  width: 100%;

  .desc {
    display: flex;
    justify-content: center;
    align-items: center;

    .cart-desc {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 10px;
      height: 100px;
    }
  }
}

.gradient-box {
  border: solid #97979723 1px;
  box-shadow: rgba(0, 0, 0, 0.294) 0px 10px 10px;
  border-radius: 1em;
  background-color: $colorBlack;
  z-index: 3;
}

.stack {
  margin-top: 1000px;
}

.stack-list {
  text-align: left;
  padding: 12px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: 0px !important;
}

ul {
  list-style: none;
  text-align: left;
}

.titulo-stack {
  border-bottom: 1px solid rgba(255, 255, 255, 0.739);
}

.what-we-do {
  width: 800px;
}

.productoss {
  background-color: #fff;
  padding-bottom: 50px;
}

.marcas-confi {
  background-color: #fff;
  color: $colorBlack;
  background-image: url("../assets/texture/left.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 200px !important;
}

.gris {
  filter: grayscale(100%);
  transition: 0.3s ease-in-out;

  &:hover {
    filter: grayscale(0%);
  }
}

.abaut-sect {
  position: relative;
}

.p-abaut {
  width: 70%;
}

.img-abaut {
  position: absolute;
  width: 100% !important;
  height: 100%;
  left: 0;
  top: 0;
  mix-blend-mode: multiply;
}

.col-abaut {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.banner-secctions {
  height: 250px;
  transform: translateY(50%);
  color: $colorBlack;

  @include responsive(mobile) {
    height: auto;
  }
}

.wrap-banner {
  background-color: $colorVerde;
  height: 250px;
  background-image: url("../assets/texture/banner.svg");
  background-size: cover;
  border-radius: 1em;
  display: flex;

  @include responsive(mobile) {
    flex-direction: column;
    height: auto;
  }

  .texto-CTA {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.9em;
    line-height: 1.8em;
    align-items: flex-start;
    padding-left: 50px;

    @include responsive(mobile) {
      align-items: center;
      width: 100%;
      text-align: center;
      font-size: 1.3em;
      padding-left: 0px;
      line-height: 1.5em;
      margin-bottom: 20px;

      h1 {
        margin-top: 20px;
        text-align: center !important;
      }
    }
  }

  .img-CAT {
    @include responsive(mobile) {
      width: 100%;
      display: none;
    }

    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// .core{
//   background-image: url('../assets/texture/core.svg');
//   background-position:center 150px;
//   background-size:  105vw;
// }</style>
