import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/lib/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#E9EF0A',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#E9EF0A',
        warning: '#FFC107'
      },
      dark: {
        primary: "#E9EF0A",
        secondary: "#707070",
        accent: "#00a9ef",
        error: "#FF5252",
        info: "#2196F3",
        success: "#E9EF0A",
        warning: "#FFC107",
      },
    },
  },
    lang: {
      locales: { es },
      current: 'es',
    },
  icons: {
    iconfont: 'fa',
  },
});
