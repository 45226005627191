<template>
  <v-app>

    <v-main>
<preloader/>
      <!-- <Cursor/> -->
      <Menu />
      <TransitionPage >
      <router-view/>
      </TransitionPage>
      <Footer />
    </v-main>
  </v-app>
</template>
<script>
import Preloader from "@/components/preloader.vue";
import Menu from "@/components/menu.vue";
import TransitionPage from "@/components/TransitionPage.vue";
import Footer from "@/components/footer.vue";
// import Cursor from "@/components/cursor.vue";
export default {
  name: "app",
  components: {
    Menu,
    Footer,
    TransitionPage,
    Preloader,
    // Cursor
  },
};
</script>

<style lang="scss">
@import "@/main.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
}
p {
  white-space: pre-wrap !important;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
}
body {
  // background-color: $colorBlack;
    overflow: hidden !important;
}
</style>
