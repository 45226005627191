<template>
  <v-container class="Team">
    <div>
      <h1 class="color-primary">LEADING TEAM</h1>
      <p class="mb-12">
        We are known for being the union of different talents that allows us to
        carry out our work with excellence and innovation:
      </p>
      <br />
    </div>

    <v-row class="mt-12">
      <v-col class="xs-12" cols="12" sm="12" md="4" lg="4" xl="4">
        <ul class="list-nombre">
          <li
            v-for="item in funder"
            :key="item.id"
            @click="Cambioo(item)"
            :class="{ list: true, active: item.id == ActiveP.id }"
          >
            <h3>
              <a class="">{{ item.name }}</a>
            </h3>
          </li>
        </ul>
      </v-col>
      <v-col class="perfil xs-12" cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
        <img src="@/assets/Exa.svg" class="exa" alt="" srcset="" />
        <transition name="slide-fade">
          <img v-if="show" :src="ActiveP.foto" class="foto" alt="" srcset="" />
        </transition>
      </v-col>
      <v-col class="info-perfil xs-12" cols="12" sm="12" md="4" lg="4" xl="4">
        <transition name="slide">
          <h2 v-if="show">
            <b class="color-primary"> {{ ActiveP.cargo }} </b>
          </h2>
        </transition>
        <transition name="slide">
          <p v-if="show">
            {{ ActiveP.descrip }}
          </p>
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Team",

  data: () => ({
    funder: [
      {
        id: 0,
        name: "Atahualpa Erazo",
        foto: require("@/assets/Ata.png"),
        cargo: "General manager",
        descrip:
          "- Over 12 years of experience in development analysis, flow design and industry best practices for corporate and financial sector - ITIL, Project Managment and Agile Scrum certifications - Sales and Credit Card market experience",
      },
      {
        id: 1,
        name: "Victor Morales",
        foto: require("@/assets/Victor.png"),
        cargo: "Chief Technology Officer",
        descrip:
          "- Over 12 years of experience in development analysis, flow design and industry best practices for corporate and financial sector - ITIL, Project Managment and Agile Scrum certifications - Sales and Credit Card market experience",
      },
      {
        id: 2,
        name: "Ricardo Aguilar",
        foto: require("@/assets/Ricardo.png"),
        cargo: "Commercial Director",
        descrip:
          "- Over 12 years of experience in development analysis, flow design and industry best practices for corporate and financial sector \n- ITIL, Project Managment and Agile Scrum certifications \n- Sales and Credit Card market experience",
      },
    ],
    ActiveP: {
      id: 0,
      name: "Atahualpa Erazo",
      foto: require("@/assets/Ata.png"),
      cargo: "General manager",
      descrip:
        "- Over 12 years of experience in development analysis, flow design and industry best practices for corporate and financial sector - ITIL, Project Managment and Agile Scrum certifications - Sales and Credit Card market experience",
    },
    show: true,
  }),
  methods: {
    Cambioo(n) {
      this.show = false;
      console.log(n);
      this.ActiveP = n;
      console.log(this.ActiveP);
      setTimeout(() => {
        this.show = true;
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/_responsive.scss";
@import "@/main.scss";
.Team {
  min-height: 300px;

  z-index: 100;
  background-color: #000;

  padding-left: 5%;
  padding-right: 5%;

  margin-top: 100px;
  margin-bottom: 150px !important;
  transition: 0.3s ease-in-out;
  @include responsive(mobile) {
    padding-top: 50px;
    min-height: 880px;
    //... all what you want
  }

  .perfil {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50px;
    height: 100%;
    @include responsive(mobile) {
      margin-top: 50px;
      height: 280px;
      //... all what you want
    }
    .foto {
      position: absolute;
      width: 40%;
      top: -20px;
      @include responsive(mobile) {
        width: 35%;
        top: -20px;
        //... all what you want
      }
    }
    .exa {
      width: 80%;
      position: absolute;
      top: 0px;
      @include responsive(mobile) {
        width: 70%;
        top: 0px;
        //... all what you want
      }
    }
  }
}
.list-nombre {
  text-align: left;
  margin-top: 50px;
  height: 100%;
  .list {
    list-style: none;
    cursor: pointer;
    border-bottom: #ffffff49 1px solid;
    margin-top: 20px;
    transition: 0.3s ease-in-out;

    a {
      color: #fff;
    }
  }
  .active {
    border-bottom: #e9ef0a 2px solid !important;
    padding-left: 20px;
  }
}
.info-perfil {
  text-align: left;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(20px) scale(0.2);
  opacity: 0;
}
.slide-enter-active {
  transition: all 0.3s ease;
}
.slide-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-enter, .slide-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
