import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ScrollOut from "scroll-out";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAbw0dd1hsQ7--s5CRNvgoSH-nfh6pZtCY",
  authDomain: "beanar-io.firebaseapp.com",
  projectId: "beanar-io",
  storageBucket: "beanar-io.appspot.com",
  messagingSenderId: "255721718435",
  appId: "1:255721718435:web:2d40e03008281698a53516",
  measurementId: "G-RELZB0WRS4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// In main.js
import VueScrollReveal from 'vue-scroll-reveal';
 
// Using ScrollReveal's default configuration
Vue.use(VueScrollReveal);
 
// OR specifying custom default options for all uses of the directive
Vue.use(VueScrollReveal, {
  class: 'v-scroll-reveal', // A CSS class applied to elements with the v-scroll-reveal directive; useful for animation overrides.
  duration: 800,
  scale: 1,
  distance: '10px',
  mobile: false
});
Vue.config.productionTip = false
Vue.use(VueScrollReveal, {
    class: 'v-scroll-reveal', // A CSS class applied to elements with the v-scroll-reveal directive; useful for animation overrides.
    duration: 800,
    scale: 1,
    distance: '10px',
    mobile: false
  });

function mousecursor() {
  console.log('Hpolas');
  if ($("body")) {
      const e = document.querySelector(".cursor-inner"),
          t = document.querySelector(".cursor-outer");
      let n, i = 0,
          o = !1;
      window.onmousemove = function (s) {
          o || (t.style.transform = "translate(" + s.clientX + "px, " + s.clientY + "px)"), e.style.transform = "translate(" + s.clientX + "px, " + s.clientY + "px)", n = s.clientY, i = s.clientX
      }, $("body").on("mouseenter", "a, .cursor-pointer", function () {
          e.classList.add("cursor-hover"), t.classList.add("cursor-hover")
      }), $("body").on("mouseleave", "a, .cursor-pointer", function () {
          $(this).is("a") && $(this).closest(".cursor-pointer").length || (e.classList.remove("cursor-hover"), t.classList.remove("cursor-hover"))
      }), e.style.visibility = "visible", t.style.visibility = "visible"
  }
}

// $(function () {
//   mousecursor();
// });


(function () {
  document.documentElement.setAttribute('data-scroll', '0');
}());
document.addEventListener('scroll', function (e) {
  var html = document.documentElement.setAttribute('data-scroll', document.documentElement.scrollTop);
});


new Vue({
    router,
    ScrollOut,
    VueScrollReveal,
    store,
    Splitting,
    vuetify,
    render: h => h(App)
}).$mount('#app')
Splitting();