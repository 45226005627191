<template>
  <!-- <div id="nav" :class="{ open_nav: BTNmenu === true }"> -->

  <div class="menu-fix">
    <v-container class="nav-wrap">
      <router-link to="/" class="logo-z" @click="BTNmenu = false">
        <img
          v-if="BTNmenu == false"
          src="@/assets/logo.svg"
          class="logo"
          
          alt=""
          srcset=""
        />
        <img
          v-else
          src="@/assets/logo-dark.svg"
          class="logo"
          alt=""
          srcset=""
        />
      </router-link>
      <ul id="lista1">
        <router-link v-for="(i, index) in links" :key="index" :to="i.link">{{
          i.name
        }}</router-link>
 <router-link to="/contact" class="logo-z" >
 
        <v-btn
          class="ml-6"
          color="primary"
          outlined
       
          elevation="12"
          raised
          rounded
        >
          Contact us</v-btn
        >
        </router-link >
      </ul>
    </v-container>
    <div class="wrap-menu">
      <div
        @click="menu"
        :class="{ btn_actions: true, active: BTNmenu == true }"
      >
        <p data-splitting>
          <b> Menu</b>
        </p>

        <div class="circule">
          <b> <v-icon small> fas fa-times </v-icon></b>
        </div>
      </div>
    </div>

    <transition name="slide-fade">
      <div v-if="BTNmenu" class="content-nav">
        <ul>
          <li
            class="li-mobile mb-2"
            v-for="(i, index) in links_mobile"
            :key="index"
            @click="BTNmenu = false"
          >
            <h3>
              <router-link :to="i.link">{{ i.name }} </router-link>
            </h3>
          </li>
    
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Menu",
  props: {
    msg: String,
  },
  data() {
    return {
      BTNmenu: false,
      links: [
        { name: "Home", link: "/" },
        { name: "Services", link: "/Services" },
        // { name: "business", link: "/business" },
        { name: "Our Work", link: "/Portafolio" },
      
      ],
      links_mobile: [
        { name: "Home", link: "/" },
        { name: "Services", link: "/Services" },
        // { name: "business", link: "/business" },
        { name: "Our Work", link: "/Portafolio" },
        { name: "Contact us", link: "/contact" },
      ],
    };
  },
  methods: {
    menu: function () {
      this.BTNmenu = !this.BTNmenu;
      console.log(this.BTNmenu);
      // alert(window.screen.width)s
    },
    // links: function (a) {
    //   this.BTNmenu = true;
    //   console.log(a);
    // },
  },
  mounted() {
    this.so = ScrollOut({
      scope: this.$el,
    });
  },
  destroyed() {
    this.so.teardown();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/_responsive.scss";
@import "@/main.scss";
.open_nav {
  background-color: rgba(0, 0, 0, 0.479);
}
#nav {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  z-index: 1000;

  // position: relative;

  .menu-fix {
    height: 60px;
    backdrop-filter: saturate(180%) blur(30px);
    background-color: $colorBlack;
      border-bottom: 1px solid rgba(82, 82, 82, 0.246);
    display: flex;
    position: fixed;
    top: 0;
    z-index: 500;
    .logo {
      width: 150px;
      z-index: 600;
      transition: 0.3s ease-in-out;
      top: 10px;
      @include responsive(mobile) {
        width: 150px;
        left: 20px;
        top: 20px;
        //... all what you want
      }
    }
  }
}
.menu-fix {
  height: 60px;
  backdrop-filter: saturate(180%) blur(30px);
  background-color: rgba(26, 26, 26, 0.787);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;

  width: 100%;
  top: 0;
  z-index: 500;
  .nav-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .logo {
      width: 150px;
      z-index: 600;
      transition: 0.3s ease-in-out;

      @include responsive(mobile) {
        width: 150px;
        left: 20px;
        top: 20px;
        //... all what you want
      }
    }
  }
}
html[data-scroll="0"] {
  .menu-fix {
    height: 70px;

    backdrop-filter: none !important;
    background-color: transparent !important;
  }
  .logo {
    margin-top: 20px;
    width: 180px !important;
    // position: absolute;
    // left: 100px;
    top: 10px;
    z-index: 600;
    @include responsive(mobile) {
      width: 150px;
      left: 20px;
      top: 20px;
      //... all what you want
    }
  }
  a {
    font-weight: bold;
    color: #ffffff !important;
    margin-left: 10px;
    text-decoration: none;
    padding: 2px 10px;
    @include responsive(mobile) {
      color: #000 !important;
      //... all what you want
    }
    &.router-link-exact-active {
      color: $colorVerde !important;
      background-color: $colorBlack;
      padding: 2px 10px;
      border-radius: 10px;
    }
  }
}

html[data-scroll="0"] {
  .wrap-menu {
    position: absolute;

    height: auto;
    // background-color: aqua;
    top: 20px;
    z-index: 600;
    min-width: 40px;
    max-width: 200px;
    right: 20px;
    display: none;
    @include responsive(mobile) {
      display: block !important;
    }
  }

  .btn_actions {
    pointer-events: visible;
    // mix-blend-mode: difference;
    min-width: 40px;
    max-width: 200px;
    height: 40px;
    border-radius: 30px;
    border: 1px solid $colorVerde;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease-in-out;
    z-index: 600;
    box-sizing: border-box;
    // display: none;

    p {
      color: $blanco;
      margin-left: 15px;
      display: none;
      margin-right: 20px;
      margin-top: 15px;
      transition: 0.5s ease-in-out;
    }
    .circule {
      transition: 0.5s ease-in-out;
      width: 30px !important;
      height: 30px !important;
      margin-right: 0px;
      background-color: $colorVerde;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      b {
        line-height: 0px;
        margin: 0px;
        margin-bottom: 2px;
        font-family: sans-serif;
      }

      // cursor: pointer;
    }
    &:hover {
      min-width: 40px;
      max-width: 250px;
      p {
        display: block;
        margin-left: 15px;
      }
      .circule {
        transition: 0.5s ease-in-out;

        margin-right: 4px;
      }
    }
  }
}
.wrap-menu {
  position: absolute;

  height: auto;
  // background-color: aqua;
  top: 10px;
  z-index: 600;
  right: 20px;
  min-width: 40px;
  max-width: 200px;
  display: none;
  @include responsive(mobile) {
    display: block !important;
  }
}
.btn_actions {
  pointer-events: visible;
  // mix-blend-mode: difference;
  min-width: 40px;
  max-width: 200px;
  height: 40px;
  border-radius: 30px;
  border: 1px solid $colorVerde;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease-in-out;
  z-index: 600;

  // display: none;
  @include responsive(mobile) {
    // display: block !important;
  }
  p {
    color: $blanco;
    margin-left: 15px;
    display: none;
    margin-right: 20px;
    margin-top: 15px;
    transition: 0.5s ease-in-out;
  }
  .circule {
    transition: 0.5s ease-in-out;
    width: 30px !important;
    height: 30px !important;
    margin-right: 0px;
    background-color: $colorVerde;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    b {
      line-height: 0px;
      margin: 0px;
      margin-bottom: 2px;
      font-family: sans-serif;
    }

    // cursor: pointer;
  }
  &:hover {
    min-width: 40px;
    max-width: 250px;
    p {
      display: block;
      margin-left: 15px;
    }
    .circule {
      transition: 0.5s ease-in-out;

      margin-right: 4px;
    }
  }
}
.active {
  border: 1px solid $colorBlack !important;

  p {
    color: $colorBlack !important;
  }
  .circule {
    background-color: $colorBlack !important;
    b {
      color: $colorVerde !important;
    }
  }
}

.content-nav {
  height: 100vh;
  position: absolute;
  width: 100%;
  right: 0;
  top: 0;
  z-index: 500;
  background-color: $colorVerde;
  color: $colorBlack;
  display: flex;
  justify-content: center;
  align-items: center;
  @include responsive(mobile) {
    ul {
      li {
        text-align: center !important;
      }
    }
  }
  h1 {
    font-size: 100px;
  }
  // transform: translateX(100%);
}

// animations
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(500px);
  // opacity: 0;
}
.texto-split {
  display: block;
  &:hover {
    display: none;
  }
}

#lista1 {
  font-family: Arial;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include responsive(mobile) {
    display: none !important;
  }
}
.separador {
  color: gray;
}
#lista1 li {
  display: inline;
  padding-left: 3px;
  padding-right: 3px;
}

a {
  font-weight: bold;
  color: #ffffff !important;
  margin-left: 10px;
  text-decoration: none;
  padding: 2px 10px;
  @include responsive(mobile) {
    color: #000 !important;
    font-size: 2em !important;

    //... all what you want
  }
  &.router-link-exact-active {
    color: $colorVerde !important;
    background-color: transparent;
    padding: 2px 10px;
    border-radius: 10px;
    @include responsive(mobile) {
      font-size: 2em !important;
      color: $colorVerde !important;
      background-color: $colorBlack;
      padding: 2px 10px;
      border-radius: 10px;

      //... all what you want
    }
  }
}
.logo-z {
  z-index: 1000 !important;
  background-color: transparent !important;
}
.li-mobile {
  color: #fff !important;
}
</style>
