<template>
  <transition name="fade">
    <div v-if="show" class="loader">
      <div class="lottie-content">
        <lottie :options="defaultOptions"   />
        <!-- v-on:animCreated="handleAnimation" -->
      </div>
    </div>
  </transition>
</template>
impor
<script>
import Lottie from "./Lottie";

import * as animationData from "@/assets/lottie/bean-pre.json";
// import * as animationData from "@/assets/lottie/lf30_editor_vsmrycuz.json";
// import * as animationData from "@/assets/lottie/ocho-super-fast.json";
// import * as animationData from "@/assets/lottie/ocho-preloader-2.json";
export default {
  name: "Preloader",
  components: {
    lottie: Lottie,
  },
  props: {},
  data() {
    return {
      anim: null,
      defaultOptions: {
        animationSpeed: 2,
        animationData: animationData.default,
        loop: false,
        autoplay: true,
      
      },
      show: true,
    };
  },
  mounted() {
    this.showLoader();
  },
  methods: {
    showLoader() {
      window.onload = setTimeout(() => {
  
        this.show = false;
      }, 6000);

    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
$colorBlack: #161616;
@import "@/_responsive.scss";

.loader {
  width: 100vw;
  height: 100vh;
  background-color: $colorBlack;
  z-index: 1000 !important;
  display: flex;

//  display: none; 
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow: hidden;
  position: fixed;
  flex-direction: column;
  .lottie-content {
    width: 200px;
    height: 200px;
  }
}
@include responsive(mobile) {
  .lottie-content {
    width: 220px !important;
    height: 100px;
  }
}
@include responsive(desktop) {
  .lottie-content {
    width: 220px !important;
    height: 100px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
