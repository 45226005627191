<template>
  <v-container class="Bean">
    <v-row>
    
        <h1 ><b>#<b class="color-primary">BEAN</b>TEAM</b></h1>
   
    </v-row>
    <v-row>
      <p data-splitting v-scroll-reveal.reset >Todos y cada uno de nuestros colaboradores aportan conocimientos esenciales para nuestras metas y objetivos.
</p>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Bean",

  data: () => ({

   show:true
  }),
  methods: {

  },
};
</script>
<style lang="scss" scoped>
@import "@/_responsive.scss";
@import "@/main.scss";
.Bean{
  height: 100vh;
}
</style>
