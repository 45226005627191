<template>
  <v-footer class="footers-wrap">
    <v-container>
      <div class="white--text text-center">
        <v-row class="mt-6">
          <v-col cols="12" md="3" sm="3" class="frijol">
            <!-- <img
              src="@/assets/frijol.svg"
              class="frijol"
              width="150px"
              alt=""
              srcset=""
            /> -->
            <img src="@/assets/logo.svg" class="frijol mb-6" width="150px" alt="" srcset="" />
            <v-card-text>
              <v-btn v-for="(item, index) in linksIcons" :key="index" class="mx-2 white--text" icon link :href="item.go"
                target="_blank">
                <v-icon size="24px">
                  {{ item.icon }}
                </v-icon>
              </v-btn>
            </v-card-text>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="2" sm="2" class="pl-2">
            <v-list-item three-line class="text-left">
              <v-list-item-content>
                <v-list-item-title>Honduras 🇭🇳</v-list-item-title>

              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="2" sm="2" class="pl-2">
            <v-list-item three-line class="text-left">
              <v-list-item-content>

                <v-list-item-subtitle v-for="(i, index) in links" :key="index">
                  <router-link :to="i.link">{{ i.name }}</router-link>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-card-text class="white--text pt-4 pb-4">
          {{ new Date().getFullYear() }} —
          <strong>Beanar<b class="color-primary">io</b> </strong>
        </v-card-text>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  props: {},
  data() {
    return {
      linksIcons: [
        {
          icon: 'mdi-facebook',
          go: 'https://www.facebook.com/beanar10/'
        },
        {
          icon: 'mdi-linkedin',
          go: 'https://www.linkedin.com/company/beanario'
        },
        {
          icon: 'mdi-instagram',
          go: 'https://www.instagram.com/beanar.io/'
        },

      ],
      links: [
        { name: "Home", link: "/" },
        { name: "Services", link: "/Services" },
        // { name: "business", link: "/business" },
        { name: "Our Work", link: "/Portafolio" },

      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/_responsive.scss";
@import "@/main.scss";

.footers-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2a2a2a;
}

a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7) !important;

  &:hover {
    color: $colorVerde !important;
  }
}

.frijol {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
</style>
