<template>
  <section class="what-we-do mt-6 margin-sections">

    <v-container fluid>
      <h1><b><b class="color-primary ">What </b> we do</b> </h1>
      <p>We can help you build your idea - whether it's a Minimum Viable Product, System integration or UX Services.</p>
      <v-row class="mt-5">
        <v-col cols="12 " md="4" sm="6" v-for="i in wado" :key="i._id">
          <v-card class="mx-auto px-2 mx-1  expande-y py-3 rounded-xl card-img" outlined>
            <img :src="i.img" alt="" srcset="">

            <div class="wrap-text-content pa-4">
              <h1 class="text-h5 font-weight-bold text-left mb-1 "> {{ i.titulo }}</h1>
              <!-- <p class="text-left p-size-xs mb-4">
                {{ i.descrip }}
              </p> -->
            </div>


          </v-card>


        </v-col>
      </v-row>
    </v-container>





  </section>
</template>

<script>
export default {
  name: "Wedo",
  data() {
    return {
      show: true,
      wado: [
        {
          _id: 0,
          titulo: "Product Development",
          descrip:
            "We turn your concepts and specifications into business-ready software that meets the highest quality standards. From first prototype to finished product, we take full responsibility for developing and managing the entire project.",
          icon: "",
          img: require('@/assets/01-.png')
        },
        {
          _id: 1,
          titulo: "System Integrator",
          descrip:
            "We take the pain out of hiring and managing top notch software engineers. Set up a dedicated development team and integrate new resources into your in-house team as if they were full-time, on-site employees.",
          icon: "",
          img: require('@/assets/02-.png')
        },
        {
          _id: 2,
          titulo: "UX Audit",
          descrip:
            "We’ll uncover your product’s usability gaps & develop actionable UX recommendations to maximize your ROI, user retention and lifespan of your app or website.",
          icon: "",
          img: require('@/assets/03-.png')
        },
        {
          _id: 3,
          titulo: "Architecture Audit",
          descrip:
            "We analyze current system status, detect architecture issues, review source code & more. It`s tailored for founders stuck with app performance issues, CEOs that survived an outsourcing nightmare or investors ready to acquire a new application.",
          icon: "",
          img: require('@/assets/04-.png')
        },
        {
          _id: 4,
          titulo: "UX/UI Design",
          descrip:
            "We have a proven UX/UI process to build products users love, without losing sight of the business goals and time to market. Our human-centered design approach for both UX and visual design strategies puts your customers at the heart of the design process, resulting in a product that they will return to again and again.",
          icon: "",
          img: require('@/assets/05-.png')
        },
        {
          _id: 5,
          titulo: "Dedicated Dev Team",
          descrip:
            "We take the pain out of hiring and managing top notch software engineers. Set up a dedicated development team and integrate new resources into your in-house team as if they were full-time, on-site employees.",
          icon: "",
          img: require('@/assets/06-.png')
        },
        // {_id:4,titulo:'MVP Definition And Launch',descrip:'We help you define your strategic innovation roadmap, buil capabilities to accelerate your pace, fast track your development process, and maximize market adoption.   ',icon:''},
        // {_id:5,titulo:'UX & UI Design',descrip:'We help you define your strategic innovation roadmap, buil capabilities to accelerate your pace, fast track your development process, and maximize market adoption.   ',icon:''},
      ],
      act_wado: {
        _id: 0,
        titulo: "Product Development",
        descrip:
          "We turn your concepts and specifications into business-ready software that meets the highest quality standards. From first prototype to finished product, we take full responsibility for developing and managing the entire project.",
        icon: "",
      },
    };
  },
  methods: {
    sendCam: function (i) {
      this.show = false;
      this.act_wado = i;
      setTimeout(() => {
        this.show = true;
      }, 1000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.what-we-do {
  margin-top: 100px;
  margin-bottom: 100px;
  width: 80%;
}

.text-center {
  text-align: center !important;
}

.v-card__subtitle,
.v-card__text {
  font-size: 0.905rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateY(10px);
  opacity: 0;
}

.card-text-wedo {
  min-height: 120px;
}

.p-size-xs {
  font-size: 0.9rem !important;
  color: #b1b1b1 !important;
}

.v-list--three-line .v-list-item .v-list-item__subtitle,
.v-list-item--three-line .v-list-item__subtitle {
  white-space: initial;
  -webkit-line-clamp: 100;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

h1 {
  font-size: 2em;
}

.card-img {
  box-shadow: rgba(0, 0, 0, 0.363) 0px 5px 10px !important;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    position: absolute;
    z-index: 0 !important;
    left: 0;
    opacity: 0.5;
    mix-blend-mode: overlay;
    top: 0;
  }
}

.wrap-text-content {
  height: 100%;
  width: 100%;
  color: #fff;
  z-index: 2 !important;
  text-align: justify;

  p {
    opacity: 0;
    height: 0px;
    overflow: hidden;
  }

  &:hover {
    p {
      height: 100%;
      overflow: none;
      opacity: 1;

    }

  }

}
</style>
